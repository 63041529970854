import { Component, ViewEncapsulation } from '@angular/core';
import { SidebarService } from './components/sidebar/sidebar.services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  title = 'dossier';
  public toggle;
  constructor(private sidebar: SidebarService) {
    this.sidebar.showNav.subscribe((navStatus) => {
      this.toggle = navStatus
    })
  }
}
