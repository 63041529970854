import { IflsLifeRenewalComponent } from './components/ifls-life-renewal/ifls-life-renewal.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ReportsComponent } from './components/reports/reports.component';
import { TeamComponent } from './components/team/team.component';
import { requesterPanelComponent } from './components/requesterPanel/requesterPanel.component';
import { ReporteeListComponent } from './components/reporteelist/reporteelist.component';
import { AnalyticsDashboardComponent } from './components/analytics-dashboard/analytics-dashboard.component';
import { AdvisorFinderDashboardComponent } from './components/advisor-finder-dashboard/advisor-finder-dashboard.component';
import { HomeComponent } from './home/home.component';
import { ErrorComponent } from './error/error.component';
import { TrmDashboardComponent } from './components/trm-dashboard/trm-dashboard.component';
import { MappedPartnersComponent } from './components/mapped-partners/mapped-partners.component';

export const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'dashboard', component: DashboardComponent },
    { path: 'dashboard/:id', component: DashboardComponent },
    { path: 'dashboard/:id/:accountId', component: DashboardComponent },
    { path: 'reports/:id', component: ReportsComponent },
    { path: 'reports/:id/:accountId', component: ReportsComponent },
    { path: 'manageteam', component: TeamComponent },
    { path: 'mappedpartners', component: MappedPartnersComponent},
    { path: 'liferenewalsreport', component: IflsLifeRenewalComponent},
    { path: 'reporteelist', component: ReporteeListComponent },
    { path: 'requesterview', component: requesterPanelComponent },
    { path: 'analytics', component: AnalyticsDashboardComponent },
    { path: 'rm-dashboard', component: AdvisorFinderDashboardComponent },
    { path: 'trm-dashboard', component: TrmDashboardComponent },
    { path: '**', component: ErrorComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
