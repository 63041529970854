import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from '../../config/config.service';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReportsComponent implements OnInit {
  public response: any
  public displayedColumns: string[] = []
  public id: number
  public accountId: string
  public diclaimer: string
  public date: Date = new Date()
  public start_date: any = this.api.getDate(new Date(this.date.getFullYear(), this.date.getMonth(), 1))
  public end_date: any = this.api.getDate(new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0))
  public table_view
  public product_type_id: Array<any> = [];
  public showProductType: boolean;
  public hide_crumbs: boolean;
  public hideProductType: Array<any> = [];
  public hideCrumbs: Array<any> = [];
  public role : any
  public selectedInsurers;
  public selectedStates;
  public selectedBusiness;

  @Input() reportId: number
  @Input() showPicker: boolean = false

  constructor(private activatedRoute: ActivatedRoute, private api: ApiService, private config: ConfigService, private auth: AuthService) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['start_date'] && params['end_date']) {
        this.start_date = params['start_date']
        this.end_date = params['end_date']
      }
      this.product_type_id = params['product_type_id']
      this.selectedInsurers = localStorage.getItem("insurers")
      this.selectedStates = localStorage.getItem("states")
      this.selectedBusiness = localStorage.getItem("business_type")
    });
    this.activatedRoute.params.subscribe(params => {
      this.id = params.id
      this.accountId = params.accountId ? params.accountId : ''
      this.apiCall()
    })
    this.table_view = this.config.getText("table_view")
    this.hideProductType = this.config.getText("hideProductType")
    this.hideCrumbs = this.config.getText("hide_crumbs")
  }
  ngOnChanges() {
    this.id = this.reportId
    this.apiCall()
  }

  renderTable(data) {
    this.response = data

    // Hide Breadcrumbs
    if (this.hideCrumbs.length > 0) {
      for (var i in this.hideCrumbs) {
        if (this.hideCrumbs[i] == this.response.dashboard_name) {
          this.hide_crumbs = false
          break
        }
        else {
          this.hide_crumbs = true
        }
      }
    }
    else {
      this.hide_crumbs = true
    }


    // Hide Product Filter
    if (this.hideProductType.length > 0) {
      for (var i in this.hideProductType) {
        if (this.hideProductType[i] == this.response.dashboard_name) {
          this.showProductType = false
          break
        }
        else {
          this.showProductType = true
        }
      }
    }
    else {
      this.showProductType = true
    }
  }

  setClass = (data) => {
    if (this.table_view[data.report_name]) {
      return this.table_view[data.report_name]
    } else {
      return "col-md-12"
    }
  }

  apiCall = () => {
    this.auth.isLoggedIn.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.api.getSideMenu().then((response) => {
          this.role = response['user_details']['emp_role']
        })
        if (this.accountId) {
          this.api.getDrillReport(this.id, this.accountId, this.start_date, this.end_date, this.product_type_id, this.selectedInsurers, this.selectedStates, this.selectedBusiness)
            .then((response) => this.renderTable(response))
        } else {
          this.api.getDashboard(this.id, this.start_date, this.end_date, this.product_type_id, this.selectedInsurers, this.selectedStates, this.selectedBusiness)
            .then((response) => this.renderTable(response))
        }
      }
    })
  }
  ngOnInit() {
  }
}