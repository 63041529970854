import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
// import 'rxjs/add/operator/toPromise';

@Injectable()
export class ConfigService {
    private _configData: any;
    private _configText: any;
    private _baseConfig: any = {
        "api": "/api/",
        "apiv2": "/api/v2/",
        "secret-key": "dCr8hEr8WWaSlxoNrh6Dst9PLzWQJnqD",
        "api-key": "945dbc41-8587-4c62-82ca-741f914077cf",
        "apiSecretKeyCO": "K0hMXX8dqyr4NUIRsmnZPjnYBiH9ttYz",
        "baseUrlCO": "http://13.127.84.36",
        "runPlatformCo": "dev",
        "appIdCO": "e0b9f629-cae2-444a-b194-6f8a1fa39829",
        "razorPayKey": "rzp_test_VYg6F7Z7oQdxNZ",
        "resetpassword": "http://uat.travassured.com/password_reset/",
        "amsURL": "https://accounts.rbstaging.in/",
        "dossierURL": "https://dossier.rbstaging.in/"
    }
    private _baseText: any = {
        "tableHeader": {},
        "contentText": {}
    }
    public versionUpdate

    constructor(private http: Http) { }

    load(): Promise<any> {
        this._configData = null;
        let day = new Date();
        day.setMilliseconds(0)
        day.setMinutes(0)
        day.setSeconds(0);
        this.versionUpdate = day.getTime();
        return this.http
            .get('config/config.json?v=' + this.versionUpdate)
            .pipe(map((res: Response) => res.json()))
            .toPromise()
            .then((data: any) => this._configData = data)
            .catch((err: any) => this._configData = this._baseConfig);
    }

    loadText(): Promise<any> {
        this._configText = null;
        let day = new Date();
        day.setMilliseconds(0)
        day.setMinutes(0)
        day.setSeconds(0);
        this.versionUpdate = day.getTime();
        return this.http
            .get('config/configText.json?v=' + this.versionUpdate)
            .pipe(map((res: Response) => res.json()))
            .toPromise()
            .then((data: any) => this._configText = data)
            .catch((err: any) => this._configText = this._baseText);
    }

    public get(key: any) {
        return this._configData[key];
    }

    public getText(key: any) {
        return this._configText[key];
    }
}