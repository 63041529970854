import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DialogComponent implements OnInit {

	public title:string
  private msg:string
  private msg_key: string = "Error"
  private data_key: string
  public showList: Array<any> = []
  error:Array<any> = []
	private callback:Function = () => console.log("dialog closed") 
  display_msg:any = ''
  constructor(
  	public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any ){
      this.title = data.title
      this.msg = data.msg
      this.data_key = data.data_key
      console.log(this.data_key);
      
      if(data.callback){
    		this.callback = data.callback
      }
      if(data.msg_key){
    		this.msg_key = data.msg_key
      }
      if(typeof data.msg === 'object'){
        if(data.msg instanceof Object){
          if(data.msg[this.data_key]){
            this.create_display_msg_list(this.msg_key, this.msg['reporting'])
          }else {
            this.create_display_msg(this.msg_key, this.msg)
          }
        }else {
          this.create_display_msg(this.msg_key, this.msg)
        }
      }else {
        this.create_display_msg(this.msg_key, this.msg)
      }
  }

  create_display_msg(key:string, massege:any){
    if(typeof massege === 'object'){
      if(massege instanceof Array ){
          this.create_display_msg(key, massege[0]);
      }else if(massege.href){
            this.callback()
  	        this.dialogRef.close();
            window.location.href = massege.href;
      }else{
        for (let keys in massege){
          this.create_display_msg(keys, massege[keys]);
        }        
      }
    }else{
      this.error.push({key:key, msg: massege})
    }
  }

  create_display_msg_list(key:string, massege:any){
    let index_key = 1
    if(massege.length === 0){
      let msg = "Reporting Not Found"
      key = "Error"
      this.create_display_msg(key, msg)
    }
    for(let msg of massege){
      this.showList.push({key:index_key++ + '.  ', msg:msg['name'] + ', ' + msg['reporting_name']})
    }
  }

  close(){
  	this.callback()
  	this.dialogRef.close();
  }
  ngOnInit() {
  }

}
