import { Injectable } from '@angular/core';
import * as $ from 'jquery'
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class PivotTodataService {
  private header: any
  constructor(private config: ConfigService) {
    this.header = this.config.getText("tableHeader")
  }

  pivotToTable = (data) => {
    var $table = $(data.data.data.html)
    var tableHead = $table.find('thead')
    var headRowsCol = tableHead.find('tr')
    var tableHeadRows = headRowsCol.map(function () {
      var headRows = $(this).find('th')
      var headRowContent = headRows.map(function () {
        return { "name": $(this).text(), "colspan": $(this).attr('colspan') ? $(this).attr('colspan') : 1 }
      })
      return headRowContent
    })
    let tableHeadersMerged = []
    for (let i = 0; i < tableHeadRows.length; i++) {
      if (i && tableHeadRows[i] != '' && tableHeadRows[i]) {
        for (let j = 0; j < tableHeadRows[i].length; j++) {
          if (tableHeadRows[i][j].name != '' && tableHeadRows[i][j].name && i) {
            tableHeadersMerged[j] = this.header[tableHeadRows[i][j].name] ? this.header[tableHeadRows[i][j].name] : tableHeadRows[i][j].name
          }
        }
      }
    }
    if (tableHeadRows[0]) {
      for (let i = 0, j = 0; i < tableHeadRows[0].length; i++) {
        for (let k = 0; k < parseInt(tableHeadRows[0][i].colspan); k++) {
          let primHeader = this.header[tableHeadRows[0][i].name]
          tableHeadersMerged[j + k] = primHeader ? primHeader + "<br>" + (tableHeadersMerged[j + k] ? tableHeadersMerged[j + k] : '') : tableHeadersMerged[j + k]
        }
        j += parseInt(tableHeadRows[0][i].colspan)
      }
    }
    var tableBody = $table.find('tbody')
    var tableBodyRow = tableBody.find('tr')
    var tableBodyRowHeader = tableBodyRow.map(function () {
      var tableBodyRowHeadercol = $(this).find('th')
      var tableBodyRowHeaderVal = tableBodyRowHeadercol.map(function () {
        return $(this).text()
      })
      return tableBodyRowHeaderVal
    })
    var tableBodyRowValue = tableBodyRow.map(function () {
      var tableBodyRowHeadercol = $(this).find('td, th')
      var tableBodyRowHeaderVal = tableBodyRowHeadercol.map(function () {
        if (isNaN($(this).text()) || $(this).text() == "") {
          return $(this).text()
        } else {
          return parseInt("" + parseFloat($(this).text()))
        }
      })
      return tableBodyRowHeaderVal
    })
    let tableRowMerged = []
    for (let i = 0; i < tableBodyRowValue.length; i++) {
      tableRowMerged[i] = {}
      for (let j = 0; j < tableBodyRowValue[i].length; j++) {
        tableRowMerged[i][tableHeadersMerged[j]] = tableBodyRowValue[i][j]
      }
    }
    var pivotTable = {
      'tableHeadRows': tableHeadRows,
      'tableBodyRowHeader': tableBodyRowHeader,
      'tableBodyRowValue': tableBodyRowValue,
      // "drillable": drillable,
      // "nextDrillId": nextDrillId,
      // "getId": this.getId
    }
    return {
      "tableRowMerged": tableRowMerged,
      "tableHeadersMerged": tableHeadersMerged
    }

  }


}
