import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from 'src/app/config/config.service';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  public path: any;
  public path2: any;
  public date: Date = new Date()
  public start_date: any = this.api.getDate(new Date(this.date.getFullYear(), this.date.getMonth(), 1))
  public end_date: any = this.api.getDate(new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0))
  public reportId: number;
  public breadcrumbs: Array<any> = [];
  public product_type_id: Array<any> = [];

  constructor(
    private config: ConfigService,
    private activatedRoute: ActivatedRoute,
    private api: ApiService
  ) {

    let product_value = this.config.getText("ALl_Products")
    this.product_type_id = product_value.product_type

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['start_date'] && params['end_date']){
        this.start_date = params['start_date']
        this.end_date = params['end_date']
      }
      if (params['product_type_id']) {
        this.product_type_id = params['product_type_id']
      }
    });

    let context = {
      "user_name": '',
      "account_id": ''
    }

    this.activatedRoute.params.subscribe(params => {
      this.reportId = params.id
      context['account_id'] = params.accountId
    });

    this.path = this.config.get("baseUrl") + '/reports/' + this.reportId + '/'
    this.path2 = '?start_date=' + this.start_date + '&end_date=' + this.end_date + '&product_type_id=' + this.product_type_id

    if (context['account_id']) {
      this.breadcrumbs = JSON.parse(localStorage.getItem("breadcrums"))
      if (this.breadcrumbs.find(ele => ele.account_id == context['account_id'])) {

        var ele_index = this.breadcrumbs.findIndex(function (item, i) {
          return item.account_id === context['account_id']
        });

        for (var i = this.breadcrumbs.length; i > ele_index; i--)
          this.breadcrumbs.splice(i, 1)

        localStorage.setItem("breadcrums", JSON.stringify(this.breadcrumbs))
        this.breadcrumbs = JSON.parse(localStorage.getItem("breadcrums"))
      }
    }
    else {
      context['user_name'] = 'Home'
      context["account_id"] = localStorage.getItem('code')
      localStorage.setItem('breadcrums', JSON.stringify([context]))
    }
  }
  ngOnInit() {
  }
}
