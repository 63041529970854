import { Component, ViewEncapsulation, OnInit, Input } from '@angular/core'
import { ApiService } from '../../services/api.service'
import { ActivatedRoute } from '@angular/router'
// import { TableComponent } from '../table/table.component'


@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit {
    public response: any = []
    public displayedColumns: string[] = []
    public id: number
    @Input() dasboardId: number

    constructor(private api: ApiService, private route: ActivatedRoute) {
        this.route.params.subscribe(params => {
            if (params['id']) {
                this.id = params['id'];
            } else if (this.dasboardId) {
                this.id = this.dasboardId
            }
        });
    }

    ngOnInit() {

    }
}