import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ConfigService } from '../../config/config.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    @Input() dashboardName: string
    public footer: Array <any> = [];
    public showFooter: boolean
    public dashboardValue: string

  constructor(
    private config: ConfigService
  ) {
    this.footer = this.config.getText("foot_note")
  }

  ngOnInit() {
    for (var i in this.footer){
      if (i==this.dashboardName){
        this.dashboardName = this.footer[i]
        this.showFooter = true
      }
    }
  }

}
