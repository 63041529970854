import { Component, OnInit, ViewEncapsulation, Inject, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../model/data.model';
import { ApiService } from '../../services/api.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-call-history',
    templateUrl: './call-history.component.html',
    styleUrls: ['./call-history.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CallHistoryComponent implements OnInit {

    statusToggle: FormGroup;
    classNameObtained: string;
    productType = environment['advisorFinderProduct'];
    callList: any;
    queryCityInfo: any;
    partnerName: string;
    statusData: string;
    trmDasshed: any;
    openMessage: boolean = false;
    messageShow: any;
    trm_first_name: string;
    trm_last_name: string;
    trm_name: string;
    partnerLogs: boolean = false;
    caseId: any;
    callHistoryDisplay: any;

    constructor(
        private formBuild: FormBuilder,
        private dialogRef: MatDialogRef<CallHistoryComponent>,
        private apiService: ApiService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        this.trmDasshed = data['trmDash'];
        this.partnerLogs = data['partnerCall'],
        this.classNameObtained = data['classNameObtained'];
        this.callList = data['callDataInfo'];
        this.caseId = data['caseIdDataInfo'];
        this.partnerName = this.callList['partner_name'];
        this.statusToggle = this.formBuild.group({
            formStatusToggle: [],
        })

        if (this.callList.status === 'open') {
            this.statusToggle.patchValue({formStatusToggle : true});
        } else {
            this.statusToggle.patchValue({formStatusToggle : false});
        }
    }

    openCallRecording(recordingData) {
        let url= `/services/communication${recordingData['recording_url']}`;
        window.open(url);
    }

    // close dialog box
    closeDialog() {
        this.dialogRef.close();
    }

    close_res(){
        this.openMessage = false;
    }

    ngOnInit() {
        this.trm_first_name = localStorage.getItem('ta_user_name');
        this.trm_last_name = localStorage.getItem('ta_user_last');
        this.trm_name = this.trm_first_name + " " + this.trm_last_name;

        if(this.caseId){
            const data = {
                case_id: this.caseId
            };
            const url = `/services/communication/api/v1/trm-partner-customer-call-details/`;

            this.apiService.postRequestedResponse(url,data, 'renewbuy-backend-header').subscribe((response) => {
                if (response) {
                    this.callHistoryDisplay = response.data.call_details;
                }

            });
        }
    }

}
