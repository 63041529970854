import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConfigService } from '../config/config.service';
import { AuthService } from '../services/auth.service';
import { HttpService } from '../services/http.service';
// import 'rxjs/add/operator/toPromise';
// import 'rxjs/add/operator/map';

@Injectable()
export class ApiService {
    fixedDates: Object;

    constructor(
        private http: HttpService,
        private router: Router, private auth: AuthService, private config: ConfigService,
        private datePipe: DatePipe,) {
        this.fixedDates = this.config.getText('fixedDateDashboard')
    }

    getDashboard(id: number, start_date: any, end_date: any, product_type_id: Array<any>, insurers: any, state: any, business_type: any) {
        // id = 9
        let url = "dashboard/" + id
        if (this.fixedDates[id]) {
            if (parseInt(this.fixedDates[id]) < 0) {
                start_date = new Date()
                end_date = new Date()
                end_date.setDate(end_date.getDate() - parseInt(this.fixedDates[id]))
            } else {
                start_date = new Date()
                end_date = new Date()
                start_date.setDate(start_date.getDate() - parseInt(this.fixedDates[id]))
            }
        }
        if (start_date && end_date) {
            let start_date_str = this.getDate(start_date)
            let end_date_str = this.getDate(end_date)
            url = "dashboard/" + id + "?start_date=" + start_date_str + "&end_date=" + end_date_str
        }
        if (product_type_id) {
            let start_date_str = this.getDate(start_date)
            let end_date_str = this.getDate(end_date)
            url = "dashboard/" + id + "?start_date=" + start_date_str + "&end_date=" + end_date_str + "&product_type_id=" + product_type_id
        }
        if(insurers || state || business_type){
            
            let start_date_str = this.getDate(start_date)
            let end_date_str = this.getDate(end_date)
            url = 'dashboard/report_filter/' + id + '/?start_date=' + start_date_str + '&end_date=' + end_date_str + '&product_type_id=' + product_type_id       
            let data = {}
            if(insurers){
                let insurer_list = insurers.split(",")
                data["insurer"] = insurer_list
            }
            if(state){
                let state_list = state.split(",")
                data["state"] = state_list
            }
            if(business_type){
                let business_type_list = business_type.split(",")
                data["business_type"] = business_type_list
            }
            return new Promise((resolve, reject) => {
                this.http.PostRequest(url, data).subscribe((response) => {
                    resolve(response)
                })
            })
        }else {
            return new Promise((resolve, reject) => {
                this.http.GetRequest(url).subscribe((response) => {
                    resolve(response)
                })
            })
        }

    }

    getDrillReport(id: number, accontId: string, start_date: Date, end_date: Date, product_type_id: Array<any>, insurers: any, state: any, business_type: any) {
        // id = 9
        let url = "dashboard/" + id + "/" + accontId
        if (this.fixedDates[id]) {
            if (parseInt(this.fixedDates[id]) < 0) {
                start_date = new Date()
                end_date = new Date()
                start_date.setDate(start_date.getDate() + parseInt(this.fixedDates[id]))
            } else {
                start_date = new Date()
                end_date = new Date()
                end_date.setDate(end_date.getDate() + parseInt(this.fixedDates[id]))
            }
        }
        if (start_date && end_date) {
            let start_date_str = this.getDate(start_date)
            let end_date_str = this.getDate(end_date)
            url = "dashboard/" + id + "/?account_id=" + accontId + "&start_date=" + start_date_str + "&end_date=" + end_date_str
        }
        if (product_type_id) {
            let start_date_str = this.getDate(start_date)
            let end_date_str = this.getDate(end_date)
            url = "dashboard/" + id + "/?account_id=" + accontId + "&start_date=" + start_date_str + "&end_date=" + end_date_str + "&product_type_id=" + product_type_id
        }
        if(insurers || state || business_type){    
            let start_date_str = this.getDate(start_date)
            let end_date_str = this.getDate(end_date)
            url = "dashboard/report_filter/" + id + "/?account_id=" + accontId + "&start_date=" + start_date_str + "&end_date=" + end_date_str + "&product_type_id=" + product_type_id
            let data = {}
            if(insurers){
                let insurer_list = insurers.split(",")
                data["insurer"] = insurer_list
            }
            if(state){
                let state_list = state.split(",")
                data["state"] = state_list
            }
            if(business_type){
                let business_type_list = business_type.split(",")
                data["business_type"] = business_type_list
            }
            return new Promise((resolve, reject) => {
                this.http.PostRequest(url, data).subscribe((response) => {
                    resolve(response)
                })
            })
        }else {
            return new Promise((resolve, reject) => {
                this.http.GetRequest(url).subscribe((response) => {
                    resolve(response)
                })
            })
        }
    }


    getSideMenu() {
        let url = "dashboard/"
        return new Promise((resolve, reject) => {
            this.http.GetRequest(url).subscribe((response) => {
                resolve(response)
            })
        })

    }

    noticeEmailApi(data: any) {
        let url = "noticeEmailApi/"
        return new Promise((resolve, reject) => {
            this.http.PostRequest(url, data).subscribe((response) => {
                resolve(response)
            })
        })
    }

    getDate(date: Date) {
        date = new Date(date)
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()
        // return  month + '-' + day
        return year + '-' + month + '-' + day
    }


    // Dialler api call
    getDailerInfo(data) {
        let url = "api/v1/reporting_check/";
        return new Promise((resolve, reject) => {
            this.http.PostRequestHeadUrl(url, data).subscribe((response) => {
                resolve(response)
            })
        })
    }

    // Get partners
    getPartnersList() {
        let url = "v1/approve_mapping/"
        return new Promise((resolve, reject) => {
            this.http.GetRequest(url).subscribe((response) => {
                resolve(response)
            })
        })

    }


    // Get partners unmappedview
    getPartnersUnMappedList() {
        let url = "v1/get_unmapped_partner/"
        return new Promise((resolve, reject) => {
            this.http.GetRequest(url).subscribe((response) => {
                resolve(response)
            })
        })

    }

    getRenewalNotice(policy_no:string, p_type:string) {
        let url = "v1/get_renewal_doc/" + "?policy_number=" + policy_no + "&product_type=" + p_type
        return new Promise((resolve, reject) =>{
            this.http.GetRequestProd(url).subscribe((response) =>{
                resolve(response)
            })
        })
    }

    getMappedPartners(data){
        let url = "v1/reporting_partners_info/"
        return new Promise((resolve, reject) =>{
            this.http.PostRequest(url,data).subscribe((response) =>{
                resolve(response)
            })
        })
    }

    getRenewalsDueData(data) {
        let url = "v1/ifls_renewal_due_data/?manager_code=" + this.auth.GetUserCode() + "&renewals_due_start_date=" + data['start_date'] + "&renewals_due_end_date=" + data['end_date']
        return new Promise((resolve, reject) =>{
            this.http.GetRequestProd(url).subscribe((response) =>{
                resolve(response)
            })
        })
    }

    getReportingManagers(data) {
        let url = "v1/current_reporting_managers/"
        return new Promise((resolve, reject) =>{
            this.http.PostRequest(url,data).subscribe((response) =>{
                resolve(response)
            })
        })
    }


    // Accept Member
    acceptMemberList(data) {
        let url = "v1/mapping_allocation/"
        var data = data;
        return new Promise((resolve, reject) => {
            this.http.PostRequest(url, data).subscribe((response) => {
                resolve(response)
            })
        })

    }


    // Detailed View
    reporteeDetailedView(data) {
        let url = "v1/reportee_list/"
        var data = data;
        return new Promise((resolve, reject) => {
            this.http.PostRequest(url, data).subscribe((response) => {
                resolve(response)
            })
        })

    }

    // submitMappedData
    submitMappedData(data) {
        let url = "v1/partner_mapping_request/"
        var data = data;
        return new Promise((resolve, reject) => {
            this.http.PostRequest(url, data).subscribe((response) => {
                resolve(response)
            })
        })

    }

    getProfileData(data) {
        let url = "v1/fetch_profile/"
        return new Promise((resolve, reject) => {
            this.http.GetRequest(url, data).subscribe((response) => {
                resolve(response)
            })
        })

    }

    /**
     * Get analytics infomation
     */

    getAnalyticsInformation(startDate: Date, endDate: Date): Observable<any> {

        let start_date;
        let end_date;
        if (startDate instanceof Date && !isNaN(startDate.valueOf())) {

            start_date = this.datePipe.transform(new Date(this.getDate(startDate)), 'dd/MM/yyyy');
            end_date = this.datePipe.transform(new Date(this.getDate(endDate)), 'dd/MM/yyyy');
        }

        if (!(startDate instanceof Date && !isNaN(startDate.valueOf()))) {
            const crntDate = new Date();
            const setStartDate = crntDate.setDate(crntDate.getMonth() - 30);
            start_date = this.datePipe.transform(new Date(this.getDate(new Date(setStartDate))), 'dd/MM/yyyy');
            end_date = this.datePipe.transform(new Date(this.getDate(new Date())), 'dd/MM/yyyy');
        }
        const url = 'v1/reporting_analysis' + '/?first_date=' + start_date + '&last_date=' + end_date;
        // const finalUrl =  url;
        return this.http.GetRequest(url).pipe(
            map(response => response),
            catchError(err => err)
        );
    }


    /***
     * cancel event for partner reassign
     */

    getCancelInfo(data) {
        let url = 'v1/partner_reassign/';

        return new Promise((resolve, reject) => {
            this.http.PostRequestHead(url, data).subscribe((response) => {
                resolve(response);
            });
        });
    };

    sendReport(data){
        let url = 'send_report/'
        return new Promise((resolve, reject) => {
            this.http.PostRequestHead(url, data).subscribe((response) => {
                resolve(response);
            });
        });
    }


    // Except Numbers
    // removeregex (data) {
    //     if (
    //         /[A-Z]/.test(data) &&
    //         /[a-z]/.test(data) &&
    //         /[!@#$%]/.test(data) &&
    //         !/[^A-Za-z!@#$%]/.test(data)
    //     ) {
    //         return data.replace(/[A-Za-z|&;$%@"<>()+]/gi,'');
    //     }
    //     return data;
    // }
    // getOrders(){
    //   let url = "order/"
    //   return new Promise((resolve, reject) => {
    //     this.http.GetRequest(url).subscribe((orders) => {
    //       resolve(orders)
    //     })
    //   })
    // }

    // getOrdersDelete(data){
    //   let url = "order/delete_orders/"
    //   return new Promise((resolve, reject) => {
    //     this.http.PostRequest(url, data).subscribe((orders) => {
    //       resolve(orders)
    //     })
    //   })
    // }

    // getBooking(){
    //   let url = "users/get_bookings_earnings/?product=TA"
    //   return new Promise((resolve, reject) => {
    //     this.http.GetRequest(url).subscribe((orders) => {
    //       resolve(orders)
    //     })
    //   })
    // }

    // payment_method(data){
    //   let url = "payment/select_payment_method/"
    //   return new Promise((resolve, reject) => {
    //     this.http.PostRequest(url, data).subscribe((orders) => {
    //       resolve(orders)
    //     })
    //   })
    // }

    // initiatePayment(data){
    //   let url = "payment/initiate_payment/"
    //   return new Promise((resolve, reject) => {
    //     this.http.PostRequest(url, data).subscribe((orders) => {
    //       resolve(orders)
    //     })
    //   })
    // }

    // getCity(data:string){
    //   let url = "search/city/?city="+data
    //   return new Promise((resolve, reject) => {
    //     this.http.GetSearchRequest(url).subscribe((orders) => {
    //       resolve(orders)
    //     })
    //   })
    // }

    // get_payment_method(data){
    //   let url = "payment/get_payment_modes/"
    //   return new Promise((resolve, reject) => {
    //     this.http.PostRequest(url, data).subscribe((orders) => {
    //       resolve(orders)
    //     })
    //   })
    // }
    // getAmsCity(data:string){
    //   let url = "search/ams_city/?city="+data
    //   return new Promise((resolve, reject) => {
    //   this.http.GetSearchRequest(url).subscribe((orders) => {
    //   resolve(orders)
    //   });
    //   });
    // }

    // get_order_validate(data:string){
    //   let url = "order/"+data+"/validate/"
    //   return new Promise((resolve, reject) => {
    //   this.http.GetSearchRequest(url).subscribe((orders) => {
    //   resolve(orders)
    //   });
    //   });
    // }

    getResponse(url: string, header: any) {

        return this.http.getRequest(url, header)
            .pipe(
                map(response => response),
                catchError(err => err)
            );

    }

    createGetApiUrl(url: string, header: any, queryObjectParam: object) {

        let urlWithParams = url;

        if (queryObjectParam) {

            Object.entries(queryObjectParam).forEach((key, index) => {

                let queryParam;
                let keyName;
                let keyVal;

                /**
                 * index 0 will be the 1st query param with (?)
                 * rest params will be added with (&)
                 */
                if (index === 0) {
                    keyName = key[0];
                    keyVal = key[1];

                    queryParam = keyName + '=' + keyVal;

                    urlWithParams = urlWithParams + '?' + queryParam;

                } else {

                    if (Array.isArray(key[1])) {
                        key[1].forEach(element => {

                            keyName = key[0];
                            keyVal = element;

                            queryParam = '&' + keyName + '=' + keyVal;

                            urlWithParams = urlWithParams + queryParam;
                        });
                    } else {

                        keyName = key[0];
                        keyVal = key[1];

                        queryParam = '&' + keyName + '=' + keyVal;

                        urlWithParams = urlWithParams + queryParam;
                    }
                }

            });

        }

        return this.http.getRequest(urlWithParams, header)
            .pipe(
                map(response => response),
                catchError(err => err)
            );
    }

    getPostResponse(url: string, data: any, header: any ) {

        return this.http.postRequest(url, data, header)
            .pipe(
                map(response => response),
                catchError(err => err)
            );
    }

    postRequestedResponse(url:any, headers:any, body:any) {
        return this.http.postRequest(url, headers, body)
            .pipe(
                map(response => response),
                catchError(err => err)
            )
    }

    getEtlData(){
        let url = "etl_jobs/"
        return new Promise((resolve, reject) =>{
            this.http.GetRequest(url).subscribe((response) =>{
                resolve(response)
            })
        })
    }

    sendRenewalLinkWhatsapp(policy_no:string, p_type:string) {
        let url = "v1/get_renewal_share_link/" + "?policy_number=" + policy_no + "&product_type=" + p_type
        return new Promise((resolve, reject) =>{
            this.http.GetRequestProd(url).subscribe((response) =>{
                resolve(response)
            })
        })
    }

    getFiltersList(start_date: any, end_date: any, product_type_id: Array<any>, data:any){
        start_date = this.datePipe.transform(new Date(this.getDate(start_date)), 'yyyy-MM-dd');
        end_date = this.datePipe.transform(new Date(this.getDate(end_date)), 'yyyy-MM-dd');
        let url = "v1/reports_filter/filter_details/?start_date=" + start_date + "&end_date=" + end_date
        return new Promise((resolve, reject) => {
            this.http.PostRequest(url, data).subscribe((response) => {
                resolve(response)
            })
        })
    }
}
