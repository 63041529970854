import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ApiService } from '../../services/api.service';
import TableDataJson from '../../product-json/advisor-dashboard.json';
import { DatePipe } from '@angular/common';
import { DialogData } from '../../model/data.model';
import { CommonModalService } from '../../services/common-modal.service';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-advisor-finder-dashboard',
    templateUrl: './advisor-finder-dashboard.component.html',
    styleUrls: ['./advisor-finder-dashboard.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AdvisorFinderDashboardComponent implements OnInit {

    partnerSearchForm: FormGroup;
    pickDateForm: FormGroup;
    tableData = TableDataJson;
    vehicleType: string;
    rmId: any;
    partnerId: any;
    productType = environment['advisorFinderProduct'];
    tableResultData = this.tableData['tableData'];
    leadsData = this.tableData['leadsData'];
    filterList = this.tableData['headerData'];
    dropDownList = this.tableData['table_dropdown'];

    totalPages: any;
    currentPage = 1;
    nextPage: number;
    pageCount: number[];
    previousPage: number;
    selectedValue: number;
    customerTableResult: any;
    partnerNameList: any;
    todayDate = new Date();

    displayErrorMessage = false;
    queryObjectParam = {};

    constructor(
        private formBuild: FormBuilder,
        private apiService: ApiService,
        private datePipe: DatePipe,
        private commonModal: CommonModalService,
        private auth: AuthService
    ) {
        this.partnerSearchForm = this.formBuild.group({
            partnerNameField: [''],
            partnerSearchField: ['']
        });

        this.pickDateForm = this.formBuild.group({
            pickDate: [''],
        });

        // getting user id to get rm data
        // this.auth.isLoggedIn.subscribe(isLoggedIn => {
        //     if (isLoggedIn) {
        //         if (this.auth.GetUserCode()) {
        //             this.rmId = this.auth.GetUserCode();
        //             this.getPartnerList();
        //             this.getCallHistory();
        //         }
        //     }
        // });

        this.auth.userExecutiveCode.subscribe(code => {
            if (code) {
                this.rmId = code;
                this.getPartnerList();
                this.getCallHistory();
            }
        });

    }


    // Call Details popup
    openCallDetailsModel(event: any, currentCallDetails: any) {

        let xplacement = event.clientX;
        if (event.clientX < 720) {
            xplacement = 30;
        } else {
            xplacement = (event.clientX - 190);
        }

        let resWidth;
        if (window.screen.width <= 767) {
            resWidth = '95%';
        } else {
            resWidth = this.tableData['callHistoryModal']['width'];
        }

        const obj: DialogData = {
            type: this.tableData['callHistoryModal']['modal-control'],
            width: this.tableData['callHistoryModal']['width'],
            height: this.tableData['callHistoryModal']['height'],
            classNameObtained: this.tableData['callHistoryModal']['class'],
            minWidth: resWidth,

            dataInfo: {
                classNameObtained: this.tableData['callHistoryModal']['class'],
                callDataInfo: currentCallDetails,
                top: (event.clientY + 7) + 'px',
                left: (xplacement) + 'px',
            },

        };

        this.commonModal.openDialog(obj).subscribe(data => {
            if (data) {
                const dataInfo = data['data'];
            }

        });

    }

    // sorting data with date
    setSelectedFilter(index, data) {

        if (index != this.selectedValue) {
            this.selectedValue = index;

            if (data['value'] == 0) {
                this.queryObjectParam['date'] = this.datePipe.transform(this.todayDate, 'yyyy-MM-dd');
            }

            if (data['value'] == 1) {
                const currentDate = new Date();
                this.queryObjectParam['date'] = this.datePipe.transform(currentDate.setDate(currentDate.getDate() + 1 ), 'yyyy-MM-dd');
            }

            this.pickDateForm.get('pickDate').reset();

            this.getCallHistory();
        }

    }

    // partner search filter
    onPartnerSelectionChange(event: any) {
        this.partnerId = event.value;

        this.queryObjectParam['partner_id'] = this.partnerId;

        this.getCallHistory();
    }

    onDateChange(){
        this.queryObjectParam['date'] = this.datePipe.transform(this.pickDateForm.get('pickDate').value, 'yyyy-MM-dd');
        this.selectedValue = 2;
        this.getCallHistory();
    }

    leadsFiltrationEvent(event: any) {

        this.queryObjectParam['call_status'] = event.value;

        this.getCallHistory();
    }

    /**
     * Getting customer data
     */
    getCallHistory() {

        let url = `/services/communication/api/v1/partner-listing/${this.rmId}/`;

        this.apiService.createGetApiUrl(url, 'renewbuy-backend-header', this.queryObjectParam).subscribe(response => {
            if (response) {
                this.customerTableResult = response;
                this.pagination();

                if (this.customerTableResult['data'] && this.customerTableResult['data'].length > 0) {
                    this.displayErrorMessage = false;
                } else {
                    this.displayErrorMessage = true;
                }
            }
        });
    }

    // get partner list
    getPartnerList() {
        let url=`/services/communication/api/v1/partner-listing-by-rm/${this.rmId}/`;

        this.apiService.getResponse(url, 'renewbuy-backend-header').subscribe(response => {
            if (response) {
                this.partnerNameList = response['data'];
                this.leadsData = response['leadsData'];
            }
        });
    }

    // pagination
    pagination() {
        this.totalPages = this.customerTableResult['total_pages'];
        this.currentPage = this.customerTableResult['current_page'];
        this.pageCount = [...Array(this.totalPages).keys()];
    }

    /**
     * set page on the basis of total age count
     * getting in api
     */
    setPage(page: number) {
        this.queryObjectParam['current_page'] = page;
        this.getCallHistory();
    }


    ngOnInit(): void {
    }

}
