import { Component, OnInit, ViewChild,NgZone, ViewEncapsulation } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router'
import { ConfigService } from '../../config/config.service';
import { AuthService } from '../../services/auth.service';
import { MatSort, MatTableDataSource, MatPaginator, MatDialog, MatRadioChange } from '@angular/material';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SelectionModel } from '@angular/cdk/collections';
import { DialogComponent } from '../../shared/dialogs/dialog/dialog.component';
import { Location } from '@angular/common';
//import {MatSort} from '@angular/material/sort';
//import { SidebarService } from './sidebar.services';
@Component({
    selector: 'app-requesterPanel',
    templateUrl: './requesterPanel.component.html',
    styleUrls: ['./requesterPanel.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class requesterPanelComponent implements OnInit {
    public navItem: any
    public url: string
    public id: any
    public base: string
    public toggle
    public partnerData: any
    public response: any
    public inTheZone: boolean = true;
    private screenWidth;
    rowColumns: any
    displayedColumns: string[];
    displayManagerColumns: string[];
    dataSource = new MatTableDataSource(this.response);
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('managerDataPaginator', { read: MatPaginator }) managerDataPaginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    private drillView: boolean = false;
    public showPagination: boolean = true;
    public paginationList: Array<any> = [];
    public selection: any
    public reportingName: any;
    public selectedProduct: string = 'motor';
    public loading: boolean = true
    managerData: any;
    partnersData: any;
    asmVisible: any;
    partnerInfo: boolean = false;
    // managerInfo: boolean = false;
    manUniqueCode: any;
    partnerCode: Array<any> = [];
    validateButton: boolean = true;



    applyFilter(filterValue: string) {
        this.partnersData.filter = filterValue.trim().toLowerCase();
        this.managerData.filter = filterValue.trim().toLowerCase();
    }
    constructor(
        private api: ApiService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private config: ConfigService,
        private auth: AuthService,
        private domSanitizer: DomSanitizer,
        private dialog: MatDialog,
        private location: Location,
        private ngZone: NgZone,
    ) {

        this.activatedRoute.queryParams.subscribe(params => {
            if (params['start_date'] && params['end_date']) {
                this.url = "?start_date=" + params['start_date'] + "&end_date=" + params['end_date']
            }
        });

        this.windowFunction();
        this.auth.isLoggedIn.subscribe((isLoggedIn) => {
            if (isLoggedIn) {
               this.getpartnerMappedList();
            }
        })
        this.paginationList = this.config.getText("hidePagination")
    }

    /**
     * segration and calling api as more  activity are being performed
     * Ask suvendra sir, whther to follow this approach or use slice and splice method
     */

    getpartnerMappedList() {

        this.api.getPartnersUnMappedList().then((response) => {
            this.loading = true;
            this.response = response;
            if (this.response.designation === "asm") {
                this.loading = false;
                this.asmVisible = true;
                Object.keys(this.response).forEach(key => {
                    if (this.selectedProduct == key) {
                        this.partnersData = response[key]['partners'];
                        this.managerData = response[key]['managers'];

                    }
                });
            }
            else {
                this.loading = false;
                this.partnersData = response['partner'];
                this.managerData = response['manager'];
                
            }


            if (this.partnersData.length > 0) {
                let tableRows;
                /**
                 * add cancel event  if partners data is avail in sys
                 */
                if (this.partnersData) {
                    tableRows  = this.partnersData; 

                    /**
                     * adding cancel in the row
                     */
                    for (let i = 0; i < tableRows.length; i++) {
                        
                        tableRows[i]['cancel'] =  this.domSanitizer.bypassSecurityTrustHtml('<button  type="button" style="cursor: pointer;background:transparent" onclick="cancelEvnt(event)" id='+tableRows[i]['unique_code']+'> cancel</button>');
                        

                    }

                  
                    this.partnersData = tableRows;

                }
                for (var i = 0; i < this.partnersData.length; i++) {
                    this.displayedColumns = Object.keys(this.partnersData[i]);

                }
                this.displayedColumns = ['select'].concat(this.displayedColumns);
               
                this.partnerInfo = true;

               
               
            }
            else {
                this.partnerInfo = false;
            }

            if (this.managerData.length > 0) {

                for (var i = 0; i < this.managerData.length; i++) {
                    this.displayManagerColumns = Object.keys(this.managerData[i]);
                }
                this.displayManagerColumns = ['select'].concat(this.displayManagerColumns);
                

            }


            this.partnersData = new MatTableDataSource(this.partnersData);
            this.selection = new SelectionModel(this.partnersData);
            this.managerData = new MatTableDataSource(this.managerData);
            setTimeout(() => {
                this.partnersData.paginator = this.paginator;
                this.managerData.paginator = this.managerDataPaginator;
                this.partnersData.sort = this.sort;
                this.managerData.sort = this.sort;
            })
            if ((this.paginationList.indexOf(this.partnersData) > -1) || (this.paginationList.indexOf(this.managerData) > -1)) {
                this.showPagination = false
            }
            //this.isAllSelected();
            // this.masterToggle();
            // this.checkboxLabel(this.partnersData);
        })


    }
    
    isAllSelected() {
        var uniqueCode;
        const numSelected = this.selection.selected;
        const selectedPartners = this.selection.selected.length;

        // if (this.selection.selected.length === 1) {
        //   setTimeout(() => {
        //     this.managerInfo = true;
        //   })
        // }
        // else {
        //   setTimeout(() => {
        //     this.managerInfo = false;
        //   })
        // }
        this.partnerCode = [];
        let last: any = numSelected[numSelected.length - 1];
        numSelected.forEach((item, index) => {
            if ('product' in item) {
                this.manUniqueCode = item.unique_code;
            } else if ((!("pincode" in last)) && item == last) {

                this.manUniqueCode = item.unique_code;
            }
            else {
                uniqueCode = item.unique_code;
                if (this.partnerCode.length == 0) {
                    this.partnerCode.push(uniqueCode);
                }
                else {
                    const index = this.partnerCode.indexOf(uniqueCode);
                    if (index == -1) {
                        this.partnerCode.push(uniqueCode);
                    }
                }
            }
            setTimeout(() => {
                if (this.manUniqueCode && this.selection.selected.length > 1) {
                    this.validateButton = false;
                }
                else {
                    this.validateButton = true;
                }
            })
        })

        const numRows = this.partnersData.data.length;
        return selectedPartners === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.partnersData.data.forEach(row => this.selection.select(row));
    }


    // /** The label for the checkbox on the passed row */
    // checkboxLabel(row) {
    //   if (!row) {
    //     return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    //   }
    //   return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    // }

    submitPartner() {
        var acceptedData =
        {
            "manager_code": this.manUniqueCode,
            "partner_list": this.partnerCode,
            "is_submit": true
        }
        this.api.submitMappedData(acceptedData).then((response) => {
            // this.router.routeReuseStrategy.shouldReuseRoute = function () {
            //   return false;
            // };
            // let data = JSON.stringify(response)
            let dialogRef = this.dialog.open(DialogComponent, {
                width: '350px',
                data: {
                    title: response['response'],
                    callback: () => { console.log('Reload page'); window.location.reload(); }
                    //msg: response,
                    // msg_key : JSON.stringify(response)
                }

            });

        })
    }


    onSelectionChange(radioChange: MatRadioChange) {
        this.partnerInfo = false;
        this.selectedProduct = radioChange.value;
        this.displayManagerColumns = [];
        this.loading = true;
        let tableRows;
        
        Object.keys(this.response).forEach(key => {
            if (this.selectedProduct == key) {
                this.loading = false;
                this.partnersData = this.response[key]['partners'];
                this.managerData = this.response[key]['managers'];

                if (this.partnersData.length > 0) {
                    this.loading = false;

                    tableRows  = this.partnersData; 

                    /**
                     * adding cancel in the row
                     */
                    for (let i = 0; i < tableRows.length; i++) {
                        
                        tableRows[i]['cancel'] =  this.domSanitizer.bypassSecurityTrustHtml('<button  type="button" style="cursor: pointer;background:transparent" onclick="cancelEvnt(event)" id='+tableRows[i]['unique_code']+'> cancel</button>');
                        

                    }

                  
                    this.partnersData = tableRows;
                    
                    for (var i = 0; i < this.partnersData.length; i++) {
                        this.displayedColumns = Object.keys(this.partnersData[i]);
                    }
                    this.displayedColumns = ['select'].concat(this.displayedColumns);
                    this.partnerInfo = true
                }

                if (this.managerData.length > 0) {
                    this.loading = false;
                    for (var i = 0; i < this.managerData.length; i++) {
                        this.displayManagerColumns = Object.keys(this.managerData[i]);
                    }
                    this.displayManagerColumns = ['select'].concat(this.displayManagerColumns);

                }

                this.selection = new SelectionModel(this.partnersData);
                this.partnersData = new MatTableDataSource(this.partnersData);
                this.managerData = new MatTableDataSource(this.managerData);
                setTimeout(() => {
                    this.partnersData.paginator = this.paginator;
                    this.managerData.paginator = this.managerDataPaginator;
                    this.partnersData.sort = this.sort;
                    this.managerData.sort = this.sort;
                })
                if ((this.paginationList.indexOf(this.partnersData) > -1) || (this.paginationList.indexOf(this.managerData) > -1)) {
                    this.showPagination = false
                }

                // this.isAllSelected();
                // this.masterToggle();
                // this.checkboxLabel(this.partnersData);

            }
        });
    }
    ngOnInit() {

    }

    windowFunction() {
        window['cancelEvnt'] = this.cancelEvnt
      }

    /**
     * cancel button invoked
     */


    cancelEvnt = event => {
        const evntId = event.currentTarget.id;
        let data ={
            partner_unique_code : evntId
        };
        this.api.getCancelInfo(data).then(response => {	
            let title: string = 'Success';
            let msg = response;
            let msg_key = "Mobile Number"
            this.getpartnerMappedList();
            if(this.screenWidth <= 767 && title == 'Success'){ 
              let aTag = document.createElement('a');
              let tellHref = '' + response 
              aTag.setAttribute('href', tellHref);
              aTag.innerText =`${response}`;
              msg = aTag; 
            }
            if(!response){
              title = "Error"
              msg = "Invalid response";
              msg_key = "Error"
            }
            if (this.inTheZone) {
              this.inTheZone = false
                  this.ngZone.run(() => {
                      let dialogRef = this.dialog.open(DialogComponent, {
                          width: '350px',
                          data: {
                              title: title,
                              msg: msg,
                              msg_key : msg_key,
                              callback: () => { this.inTheZone = true }
                          }
                      });
                  })
            }  
            
                  
          })

    }

}
