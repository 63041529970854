import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogData } from '../model/data.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import modalJson from '../product-json/modal-config.json';
import { CallHistoryComponent } from '../modal-component/call-history/call-history.component';
import { ConnectCallComponent } from '../modal-component/connect-call/connect-call.component';

@Injectable({
    providedIn: 'root'
})
export class CommonModalService {

    modalControl: any = modalJson['modal-number'];
    dialogConfig = new MatDialogConfig();

    constructor(
        public dialog: MatDialog,
        private overlay: Overlay
    ) {
        this.dialogConfig.height = '50%';
        this.dialogConfig.width = '50%';
        // Over-riding default data value for modal window - if required
        this.dialogConfig.maxWidth = '90vw';
        this.dialogConfig.maxHeight = '100vh';
        this.dialogConfig.position = {
            top: '5%',
        };
        this.dialogConfig.minWidth = '50%';
    }

    openDialog(obj: DialogData): Observable<any> {


        let childComponent: any = null;
        const { width, height, minWidth, classNameObtained, dataInfo } = obj;
        const scrollStrategy = this.overlay.scrollStrategies.reposition();
        this.dialogConfig.data = dataInfo;

        /**
         * These need to be changed  as number should be configurable.
         * Curently it is not .
         * TODOS- On urgent basis need to change
         */
        if (width) {
            this.dialogConfig.width = width;
        }
        if (height) {
            this.dialogConfig.height = height;
        }

        if (minWidth) {
            this.dialogConfig.minWidth = minWidth;

        }

        if (classNameObtained) {
            this.dialogConfig.panelClass = classNameObtained;
        }

        if (this.dialogConfig.data['top']) {
            this.dialogConfig.position = {
                top: this.dialogConfig.data['top']
            };
        }
        if (this.dialogConfig.data['left']) {
            this.dialogConfig.position['left'] = this.dialogConfig.data['left'];
        }


        if (obj.type === this.modalControl['call-history-detail']) {

            childComponent = CallHistoryComponent;
        }

        if (obj.type === this.modalControl['ConnectCallComponent']) {

            childComponent = ConnectCallComponent;
        }

        if (childComponent === null) {

            return null;
        }

        const dialogRef = this.dialog.open(childComponent, {
            data: this.dialogConfig.data,
            position: this.dialogConfig.position,
            width: this.dialogConfig.width,
            height: this.dialogConfig.height,
            minWidth: this.dialogConfig.minWidth,
            panelClass: this.dialogConfig.panelClass,
            scrollStrategy,
        });

        return dialogRef.afterClosed();


    }

}


