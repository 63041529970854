import { Component, OnInit, ViewEncapsulation, Inject, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../model/data.model';
import { ApiService } from '../../services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import connectJson from '../../product-json/advisor-dashboard.json';
import { ThrowStmt } from '@angular/compiler';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-connect-call',
    templateUrl: './connect-call.component.html',
    styleUrls: ['./connect-call.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ConnectCallComponent implements OnInit {

    callConnectJson = connectJson;
    customerCallForm: FormGroup;
    partnerCallForm: FormGroup;
    showOtherPartner: FormGroup;
    statusToggle: FormGroup;
    callGroup: FormGroup;
    NewPartners: FormGroup;
    classNameObtained: string;
    productType = environment['advisorFinderProduct'];
    callList: any;
    queryCityInfo: any;
    partnerName: string;
    statusData: string;
    trmDasshed: any;
    radioValue: any;
    trmId: string;
    openMessage: boolean = false;
    isCustomerCallActive: boolean = false;
    isPartnerCallActive: boolean = false;
    timeSlotList: any;
    selectedDateValue: number;
    pickDateMinRange: any;
    messageShow: boolean = false;
    selectFromCalender: string;
    sechduledDate: string;
    selectedSlotTime: string;
    isStatusChanged: boolean = false;
    isNewPartner: boolean = true;
    partnerSelection: boolean = false;
    newPartnerList: any;


    constructor(
        private formBuild: FormBuilder,
        private dialogRef: MatDialogRef<ConnectCallComponent>,
        private apiService: ApiService,
        private datePipe: DatePipe,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        this.trmId = localStorage.getItem('code');
        this.trmDasshed = data['trmDash']
        this.classNameObtained = data['classNameObtained'];
        this.callList = data['callDataInfo'];
        this.partnerName = this.callList['partner_name'];
        this.statusToggle = this.formBuild.group({
            formStatusToggle: [],
        })

        this.callGroup = this.formBuild.group({
            trmCallRadio: ['1'],
        });

        if (this.callList.status === 'open') {
            this.statusToggle.patchValue({formStatusToggle : true});
        } else {
            this.statusToggle.patchValue({formStatusToggle : false});
        }

        // customer call details form
        this.customerCallForm = this.formBuild.group({
            callStatus: [this.callConnectJson['customerCallStatus']['default']],
            callChoice: [this.callConnectJson['customerChoice']['default']],
            callAppointment: [],
            noteTextField: [],
            pickDate: []
        });

        // partner call details form
        this.partnerCallForm = this.formBuild.group({
            callStatus: [this.callConnectJson['customerCallStatus']['default']],
            notConnectReason: [this.callConnectJson['notConnectReason']['default']],
            callChoice: [this.callConnectJson['partnerChoice']['default']],
            callBackTime: [],
            noteTextField: [],
            pickDate: []
        });

        this.NewPartners = this.formBuild.group({
            partnerList: []
        })

        this.showOtherPartner = this.formBuild.group({
            customerPin: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('^[0-9]*$')]]
        });
    }

    // close dialog box
    closeDialog() {
        this.dialogRef.close();
    }

    onToggleChange() {

        this.isStatusChanged = true;

        const statusSelected = this.statusToggle.get('formStatusToggle').value;

        if (statusSelected) {
            this.statusData = 'open';
        } else{
            this.statusData = 'closed';
        }
    }


    customerCall() {

        this.isCustomerCallActive = true;

        const data = {
            is_customer: true,
            case_id: this.callList.case_id,
            to_user_id: this.callList.customer_id,
            trm_id: this.trmId
        }

        this.apiCalling(data);
    }

    close_res(){
        this.openMessage = false;
    }

    partnerCall() {

        this.isPartnerCallActive = true;

        const data = {
            is_customer: false,
            case_id: this.callList.case_id,
            to_user_id: this.callList.partner_id,
            trm_id: this.trmId
        }
        this.apiCalling(data)
    }

    apiCalling(data){
        const url = `/services/communication/api/v1/trm-call-to-connect/`;

        this.apiService.postRequestedResponse(url,data, 'renewbuy-backend-header').subscribe((response) => {
            if (response) {
                // if(data.to_user_id == this.callList.partner_id){
                    this.openMessage = true;
                    this.messageShow = response.message;
                    setTimeout(()=>{
                        this.openMessage = false;
                    }, 3000)
                // }
                // this.openMessage = true;
                    // this.MatSnackBar.open("Calling", "Ok", {
                    //     duration: 3000,
                    // });
                }

        });
    }

    // getting slot time
    getSelectedSlot(slotTime: any) {
        this.selectedSlotTime = slotTime.source.triggerValue;
    }

    /**
     * form submission based on which form is selected
     * submit partner form if partner is selected or
     * submit customer form if customer is selected
     * submit status changed on submit button only
     */
    submitForm() {

        // status data submission
        if (this.isStatusChanged) {

            const data = {
                case_id: this.callList.case_id,
                status: this.statusData
            };

            const url = `/services/communication/api/v1/trm-update-status/`;

            this.apiService.getPostResponse(url,data, 'renewbuy-backend-header').subscribe((response) => {

                if (response) {
                    this.dialogRef.close({ event: 'close', data: response });
                }

            });
        }

        // customer form submission
        if (this.customerCallForm.touched && this.customerCallForm.valid) {

            // call schedule
            if (this.customerCallForm.get('callChoice').value == 3) {

                if (this.selectFromCalender) {
                    this.sechduledDate = this.selectFromCalender;
                } else if (this.selectedDateValue == 0) {
                    this.sechduledDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd')
                } else {
                    const currentDate = new Date();
                    currentDate.setDate(currentDate.getDate() + 1);
                    this.sechduledDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd')
                }

                const data = {
                    customer_id: this.callList['customer_id'],
                    partner_id: this.callList['partner_id'],
                    product_code: this.callList['product_code'],
                    case_id: this.callList['case_id'],
                    slot_id: this.customerCallForm.get('callAppointment').value,
                    start_end_time: this.selectedSlotTime,
                    date: this.sechduledDate
                }

                const url = `/services/communication/api/v1/scheduler/book-customer-slot/`;

                this.apiService.postRequestedResponse(url,data, 'renewbuy-backend-header').subscribe((response) => {
                    if (response) {
                        this.openMessage = true;
                        this.messageShow = response.message;
                        setTimeout(()=>{
                            this.openMessage = false;
                        }, 3000)

                    }

                });
            }

            const dataRemark = {
                case_id: this.callList.case_id,
                remark: this.customerCallForm.get('callChoice').value,
                is_connected: this.customerCallForm.get('callStatus').value,
                notes: this.customerCallForm.get('noteTextField').value
            }

            const urlRemark = `/services/communication/api/v1/trm-update-remarks/`;

            this.apiService.postRequestedResponse(urlRemark, dataRemark, 'renewbuy-backend-header').subscribe((response) => {
                if (response) {
                    this.openMessage = true;
                    this.messageShow = response.message;
                    this.customerCallForm.reset();
                    this.isCustomerCallActive = false;
                    setTimeout(()=>{
                        this.openMessage = false;
                     }, 5000)

                }

            });
        }

        // partner form submission
        if (this.partnerCallForm.touched && this.partnerCallForm.valid) {

            if (this.partnerCallForm.get('callChoice').value == 1) {

                this.sechduledDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd')

                const data = {
                    customer_id: this.callList['customer_id'],
                    partner_id: this.callList['partner_id'],
                    product_code: this.callList['product_code'],
                    case_id: this.callList['case_id'],
                    slot_id: this.partnerCallForm.get('callBackTime').value,
                    start_end_time: this.selectedSlotTime,
                    date: this.sechduledDate
                }

                const url = `/services/communication/api/v1/scheduler/book-customer-slot/`;

                this.apiService.postRequestedResponse(url,data, 'renewbuy-backend-header').subscribe((response) => {
                    if (response) {
                        this.openMessage = true;
                        this.messageShow = response.message;
                        setTimeout(()=>{
                            this.openMessage = false;
                         }, 3000)

                    }

                });
            }

            const dataRemark = {
                case_id: this.callList.case_id,
                remark: this.partnerCallForm.get('callChoice').value,
                is_connected: this.partnerCallForm.get('callStatus').value,
                notes: this.partnerCallForm.get('noteTextField').value,
                reason: this.partnerCallForm.get('notConnectReason').value
            }

            const urlRemark = `/services/communication/api/v1/trm-update-remarks/`;
            this.apiService.postRequestedResponse(urlRemark, dataRemark, 'renewbuy-backend-header').subscribe((response) => {
                if (response) {
                    this.openMessage = true;
                    this.messageShow = response.message;
                    this.partnerCallForm.reset();
                    this.isPartnerCallActive = false;
                    setTimeout(()=>{
                        this.openMessage = false;
                     }, 5000)

                }

            });
        }

        // Selected Partner
        if(this.NewPartners.touched && this.NewPartners.valid){
            const advisorSearchData = {
                case_id:this.callList.case_id,
                new_partner_username: this.NewPartners.get('partnerList').value
            }

            const urlRemark = `/services/communication/api/v1/trm-assign-new-partner/`;
            this.apiService.postRequestedResponse(urlRemark, advisorSearchData, 'renewbuy-backend-header').subscribe((response) => {
                if (response) {
                    this.openMessage = true;
                    this.messageShow = response.message;
                    setTimeout(()=>{
                        this.openMessage = false;
                        this.dialogRef.close({ event: 'close', data: response });
                    }, 3000)

                }

            });
        }

    }

    // Search Advisor for recommendation
    postAdvisorSearch(isValid: boolean): void{
        if (isValid){
            const advisorSearchData = {
                pincode: this.showOtherPartner.get('customerPin').value,
                partner_ams_id: this.trmId
            }

            const urlRemark = `/services/partner/trm/recommended_partners/`;
            this.apiService.postRequestedResponse(urlRemark, advisorSearchData, 'renewbuy-backend-header').subscribe((response) => {
                if (response) {
                    this.newPartnerList = response;
                    this.isNewPartner = false;
                    this.partnerSelection = true;
                }

            });
        }
    }

    // form reset on changing calling partner/customer
    onRadioChange(){
        this.radioValue = this.callGroup.get('trmCallRadio').value;

        if (this.radioValue === '2') {
            if (this.isPartnerCallActive) {
                this.isPartnerCallActive = false;
            }
            this.partnerCallForm.reset();
            this.customerCallForm.reset();
        } else {
            if (this.isCustomerCallActive) {
                this.isCustomerCallActive = false;
            }
            this.isPartnerCallActive = false;
            this.partnerCallForm.reset();
            this.customerCallForm.reset();
        }

    }

    // slot api
    slotDataApi(){
        const url = '/services/communication/api/v1/scheduler/slots/';

            this.apiService.getResponse(url, 'renewbuy-backend-header').subscribe(response => {
                if (response) {
                    this.timeSlotList = response['slots'];
                }
            });
    }

    onPartnerChangeChoice(event) {
        if (event.value === 1) {
            const url = '/services/communication/api/v1/scheduler/slots/';

            this.apiService.getResponse(url, 'renewbuy-backend-header').subscribe(response => {
                if(response){
                    this.timeSlotList = response['slots'];
                }
            })
        }
    }

    // sorting data with date
    setSelectedFilter(index, data) {

        if (index != this.selectedDateValue) {
            this.selectedDateValue = index;
        }

    }

    onDateChange(){

        this.selectFromCalender = this.datePipe.transform(this.customerCallForm.get('pickDate').value, 'yyyy-MM-dd')
        this.selectedDateValue = 3;
    }

    ngOnInit() {
        this.radioValue = this.callGroup.get('trmCallRadio').value;
        this.pickDateMinRange = new Date();
        this.slotDataApi();
    }

}
